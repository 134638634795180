
const controles = {
  namespaced: true,

  state: () => ({
    showTerminar: false,
    loading: false,
    waiting: false,
    mensaje: { color: 'error', mensaje: 'TEXTO DE PRUEBA', visible: false },
    conexionSAP: true
  }),

  getters: {
    showTerminar: (state) => {
      return state.showTerminar
    },
    loading: (state) => {
      return state.loading
    },
    waiting: (state) => {
      return state.waiting
    },
    mensaje: (state) => {
      return state.mensaje
    },
    conexionSAP: (state) => {
      return state.conexionSAP
    }
  },
  mutations: {
    CAMBIA_SHOW_TERMINAR (state, showTerminar) {
      state.showTerminar = showTerminar
    },
    CAMBIA_LOADING (state, loading) {
      state.loading = loading
    },
    CAMBIA_WAITING (state, waiting) {
      state.loading = waiting
    },
    CAMBIA_MENSAJE (state, mensaje) {
      state.mensaje = mensaje
    },
    CAMBIA_CONEXION_SAP (state, conexionSAP) {
      state.conexionSAP = conexionSAP
    }

  },
  actions: {
    cambia_showTerminar ({ commit }, showTerminar) {
      commit('CAMBIA_SHOW_TERMINAR', showTerminar)
    },
    cambia_loading ({ commit }, loading) {
      commit('CAMBIA_LOADING', loading)
    },
    cambia_waiting ({ commit }, waiting) {
      commit('CAMBIA_WAITING', waiting)
    },
    cambia_mensaje ({ commit }, mensaje) {
      commit('CAMBIA_MENSAJE', mensaje)
    },
    cambia_conexionSAP ({ commit }, conexionSAP) {
      commit('CAMBIA_CONEXION_SAP', conexionSAP)
    }
  }
}

export default controles
