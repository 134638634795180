import { timestamp, updateDocumentId } from '@/firebase/helpers'
import { apiSap } from '../js/sap'
import { esfechaMenorA } from '../js/utilities'

import store from '@/store'

async function tipoCambio({ tienda }) {
  if (!tienda) { return null }

  const { datos, error } = await apiSap('general/tipo-cambio', { tienda: tienda })
  if (error) {
    return null
  }
  return datos.exchange
}

async function actualizaTipoCambio(tienda, tipoCambioOriginal) {
  const nuevoTipoCambio = await tipoCambio({ tienda: tienda })
  if (!nuevoTipoCambio) { return tipoCambioOriginal }

  await updateDocumentId('tiendas', tienda, { tipoCambio: nuevoTipoCambio, ultimaActualizacion: timestamp(new Date()) })
  return nuevoTipoCambio
}

async function actualizaTipoCambioTiendaSeleccionada() {
  const tienda = store.getters['store_cotizando/tienda']

  let ultimaActualizacion = null

  if (tienda.ultimaActualizacion) {
    ultimaActualizacion = tienda.ultimaActualizacion
  }

  if (!tienda.tipoCambio || !ultimaActualizacion || esfechaMenorA(ultimaActualizacion, 4, 'hours')) {
    const nuevoTipoCambio = await actualizaTipoCambio(tienda.tienda, tienda.tipoCambio)
    store.dispatch('store_tiendas/cambia_tipoCambioTienda', { tienda, nuevoTipoCambio })
    store.dispatch('store_cotizando/cambia_tipoCambio', nuevoTipoCambio)
  } 
  
}

export { actualizaTipoCambioTiendaSeleccionada }
