
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from '@/store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

// import 'roboto-fontface/css/roboto/roboto-fontface.css'
// import '@mdi/font/css/materialdesignicons.css'

// import VueInteractJs from "vue-interactjs";
// Vue.use(VueInteractJs);

// import VueOffline from 'vue-offline'
// Vue.use(VueOffline)

// Algolia  VS.  ELASTIC     https://www.elastic.co/es/

import InstantSearch from 'vue-instantsearch'
import currency from 'currency.js'
Vue.use(InstantSearch)

Vue.prototype.currency = currency

// import * as VueGoogleMaps from 'vue2-google-maps'

// import VueHtmlToPaper from 'vue-html-to-paper'

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg',
//     libraries: 'places',
//     region: 'mx',
//     language: 'es'
//   }
// })
// const options = {
//   specs: [
//     'fullscreen=yes',
//     'titlebar=no',
//     'scrollbars=no'
//   ]
// }
// Vue.use(VueHtmlToPaper, options)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  updated () {
    // // console.log('New content is available: Please refresh.')
    // window.location.reload(true)
  },
  render: h => h(App)
}).$mount('#app')
