'use strict'

import { Timestamp } from '@/firebase/firebase'
import { mayusculas, mayusculasNombresPropios } from '@/js/utilities'

class Producto {
  constructor (sku, descripcion, unidadMedida, unidadesMedida, marca, categoria, image) {
    this.sku = sku || ''
    this.descripcion = descripcion || ''
    this.unidadMedida = unidadMedida || ''
    this.unidadesMedida = unidadesMedida || []
    this.marca = marca || ''
    this.categoria = categoria || ''
    this.image = image || ''
  }
}

class Precios {
  constructor (sociedad, tienda, sku, unidadMedida, precioMenudeo, precioMayoreo, existencia, ultimaActualizacion) {
    this.sociedad = sociedad || ''
    this.tienda = tienda || ''
    this.sku = sku || ''
    this.unidadMedida = unidadMedida || ''
    this.precioMenudeo = precioMenudeo || 0
    this.precioMayoreo = precioMayoreo || 0
    this.existencia = existencia || 0
    this.ultimaActualizacion = ultimaActualizacion || new Date('1900/01/01')
  }
}

class Vendedor {
  constructor (email, nombre, vendedor) {
    this.email = email || ''
    this.nombre = nombre || ''
    this.vendedor = vendedor || ''
  }
}

class Direccion {
  constructor (calle, numero, colonia, ciudad, estado, codigoPostal, pais) {
    this.calle = calle || ''
    this.numero = numero || ''
    this.colonia = colonia || ''
    this.ciudad = ciudad || ''
    this.estado = estado || ''
    this.codigoPostal = codigoPostal || ''
    this.pais = pais || ''
  }
}

class Entrega extends Direccion {
  constructor (nombre, telefono, email, calle, numero, colonia, ciudad, estado, codigoPostal, pais, latitud, longitud, notas) {
    super(calle, numero, colonia, ciudad, estado, codigoPostal, pais)
    this.nombre = nombre || ''
    this.telefono = telefono || ''
    this.email = email || ''
    this.latitud = latitud || ''
    this.longitud = longitud || ''
    this.notas = notas || ''
  }
}

class Cliente extends Direccion {
  constructor (id, nombre, rfc, telefono, email, calle, numero, colonia, ciudad, estado, codigoPostal, pais, disponible, ultimoPago, estatus) {
    super(calle, numero, colonia, ciudad, estado, codigoPostal, pais)
    this.id = id || ''
    this.rfc = rfc || ''
    this.nombre = nombre || ''
    this.email = email || ''
    this.telefono = telefono || ''
    this.disponible = disponible || 0
    this.ultimoPago = ultimoPago || new Date('1900/01/01')
    this.estatus = estatus || ''
  }
}

class Tienda {
  /**
     * @param  {string} razonSocial ejemplo: "Kuroda Norte, S.A. de C.V."
     * @param  {string} sociedad ejemplo: "KNO"
     * @param  {string} sucursal ejemplo: "20 de Noviembre"
     * @param  {string} tienda ejemplo: ejemplo: "KN00"
     * @param  {string} direccion ejemplo: "Av. 20 De Noviembre #12177"
     * @param  {string} colonia ejemplo: "Col. 20 De Noviembre"
     * @param  {string} ciudad ejemplo: "Tijuana"
     * @param  {string} estado ejemplo: "BC"
     * @param  {number} codigoPostal ejemplo: 22100
     * @param  {string} telefono ejemplo: "(664) 681-9024"
     * @param  {String} rfc ejemplo: "KNO900118U6A"
     * @param  {number} tasaIva ejemplo: 8
     * @param  {number} tipoCambio ejemplo: 19.99
     * @param  {date}   ultimaActualizacion ejemplo: new Date()
     */
  constructor (
    razonSocial,
    sociedad,
    sucursal,
    tienda,
    direccion,
    colonia,
    ciudad,
    estado,
    codigoPostal,
    telefono,
    rfc,
    tasaIva,
    tipoCambio,
    ultimaActualizacion
  ) {
    this.razonSocial = mayusculas(razonSocial)
    this.sociedad = mayusculas(sociedad)
    this.sucursal = mayusculas(sucursal)
    this.tienda = mayusculas(tienda)
    this.direccion = mayusculas(direccion)
    this.colonia = mayusculas(colonia)
    this.ciudad = mayusculas(ciudad)
    this.estado = mayusculas(estado)
    this.codigoPostal = codigoPostal || ''
    this.telefono = telefono || ''
    this.rfc = mayusculas(rfc)
    this.tasaIva = tasaIva || 0
    this.tipoCambio = tipoCambio || 0
    this.ultimaActualizacion = ultimaActualizacion || new Date('1900/01/01')
  }

  asFirebase () {
    const ultimaActualizacion = (this.ultimaActualizacion == null ? null : Timestamp.fromDate(this.ultimaActualizacion))

    return {
      razonSocial: this.razonSocial,
      sociedad: this.sociedad,
      sucursal: this.sucursal,
      tienda: this.tienda,
      direccion: this.direccion,
      colonia: this.colonia,
      ciudad: this.ciudad,
      estado: this.estado,
      codigoPostal: this.codigoPostal,
      telefono: this.telefono,
      rfc: this.rfc,
      tasaIva: this.tasaIva,
      tipoCambio: this.tipoCambio,
      ultimaActualizacion: ultimaActualizacion
    }
  }
}

class Usuario {
  /**
     * @param  {string} email
     * @param  {string} nombre
     * @param  {string} sociedad
     * @param  {number} vendedor
     * @param  {(string|Array.)} tiendas
     * @param  {string} tiendaDefault
     * @param  {(string|Array.)} canales
     * @param  {string} canalSeleccionado
     * @param  {(string|Array.)} tiposVenta
     * @param  {string} tipoVentaSeleccionado
     * @param  {(string|Array.)} tiposEntrega
     * @param  {string} tipoEntregaSeleccionado
     * @param  {(string|Numeric.)} tasasIva
     * @param  {number} tasaIvaSeleccionada
     * @param  {boolean} admin
     * @param  {boolean} config
     * @param  {boolean} existenciasOtrasRazonesSociales
     * @param  {boolean} imagenCotizando
     * @param  {boolean} infoProductoQR
     */
  constructor (
    email, nombre, sociedad, vendedor,
    tiendas, tiendaDefault,
    canales, canalSeleccionado,
    tiposVenta, tipoVentaSeleccionado,
    tiposEntrega, tipoEntregaSeleccionado,
    tasasIva, tasaIvaSeleccionada,
    admin,
    config,
    existenciasOtrasRazonesSociales,
    imagenCotizando,
    infoProductoQR
  ) {
    this.email = email
    this.nombre = mayusculasNombresPropios(nombre)
    this.sociedad = sociedad
    this.vendedor = vendedor
    this.tiendas = tiendas
    this.tiendaDefault = tiendaDefault
    this.canales = canales
    this.canalSeleccionado = canalSeleccionado
    this.tiposVenta = tiposVenta
    this.tipoVentaSeleccionado = tipoVentaSeleccionado
    this.tiposEntrega = tiposEntrega
    this.tipoEntregaSeleccionado = tipoEntregaSeleccionado
    this.tasasIva = tasasIva
    this.tasaIvaSeleccionada = tasaIvaSeleccionada
    this.admin = admin
    this.config = config
    this.existenciasOtrasRazonesSociales = existenciasOtrasRazonesSociales
    this.imagenCotizando = imagenCotizando
    this.infoProductoQR = infoProductoQR
  }
}

// class Person {
//     constructor(name, age) {
//         this.name = name
//         this.age = age
//     }
//     schema() {
//         return {
//             "type": "object",
//             "properties": {
//                 "name": {
//                     "type": "string", "minLength": 3, "maxLength": 30
//                 },
//                 "age": { "type": "integer", minimum: 0 }
//             },
//             "required": ["name", "age"]
//         }
//     }
// }
// import { validate } from 'jsonschema'
// function validateObjectWithSchema(obj) {
//     return validate(obj, obj.schema())
// }

export { Cliente, Entrega, Tienda, Usuario, Vendedor, Producto, Precios }
