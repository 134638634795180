import {
  db,
  query,
  collection,
  where,
  deleteDoc,
  doc,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  Timestamp,
} from "@/firebase/firebase"
import store from "@/store"

function timestamp(dateJS) {
  // Para Grabar en Firestore
  return Timestamp.fromDate(dateJS)
}

async function deleteDocumentsWhereWhere(
  collectionName,
  fieldName1,
  condition1,
  searchValue1,
  fieldName2,
  condition2,
  searchValue2
) {
  const promises = []

  const q = query(
    collection(db, `${collectionName}`),
    where(`${fieldName1}`, `${condition1}`, searchValue1),
    where(`${fieldName2}`, `${condition2}`, searchValue2)
  )

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach(async (doc) => {
    promises.push(deleteDoc(doc.ref))
  })

  try {
    await Promise.allSettled(promises)
  } catch (error) {
    await logs({ opcion: "deleteDocumentsWhereWhere", error })
    throw new Error(
      `Error Al Borrar en ${collectionName}, donde ${fieldName1}${condition1}${searchValue1} AND ${fieldName2}${condition2}${searchValue2}`,
      error
    )
  }
}

async function deleteDocumentId(collectionName, docId) {
  try {
    const suspendidaRef = doc(collection(db, `${collectionName}`), `${docId}`)
    await deleteDoc(suspendidaRef)
  } catch (error) {
    await logs({ opcion: "deleteDocumentId", error })
    throw new Error(`Error Al Borrar en ${collectionName}, el Documento id ${docId}`, error)
  }
}


async function addDocument(collectionName, docData) {
  try {
    const ref = await addDoc(collection(db, `${collectionName}`), docData)
    return ref.id
  } catch (error) {
    console.error(error)
    await logs({ opcion: "Error addDocument", error })
    throw new Error(`Error Al Agregar Documento en ${collectionName}`)
  }
}

async function updateDocumentId(collectionName, docId, docData) {
  try {
    return await setDoc(doc(db, `${collectionName}`, `${docId}`), docData, { merge: true })
  } catch (error) {
    await logs({ opcion: "updateDocumentId", error })
    throw new Error(`Error Al Actualizar a ${collectionName}, el Documento id ${docId} con los datos ${docData}`, error)
  }
}

async function addDocumentId(collectionName, docId, docData) {
  try {
    return await setDoc(doc(db, `${collectionName}`, `${docId}`), docData)
  } catch (error) {
    await logs({ opcion: "addDocumentId", error })
    throw new Error(`Error Al Agregar a ${collectionName}, el Documento id ${docId} con los datos ${docData}`, error)
  }
}

async function listeningQuery(collectionName, fieldName, condition, searchValue) {
  const queryCollection = collection(db, `${collectionName}`)
  return query(queryCollection, where(`${fieldName}`, `${condition}`, searchValue))
}

async function getDocsFromCollectionWhere(collectionName, fieldName, condition, searchValue) {
  const data = []
  let querySnapshot = null

  const ref = query(collection(db, `${collectionName}`), where(`${fieldName}`, `${condition}`, searchValue))
  querySnapshot = await getDocs(ref)
  for (const doc of querySnapshot.docs) {
    data.push({ id: doc.id, ...doc.data() })
  }
  return data
}

async function getAllDocsFrom(collectionName) {
  const data = []
  const querySnapshot = await getDocs(collection(db, collectionName))

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    data.push({ id: doc.id, ...doc.data() })
  })

  return data
}

async function getDocumentWithId(collectionName, docId) {
  if (docId) {
    try {
      const docRef = doc(db, `${collectionName}`, `${docId}`)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        return docSnap.data()
      } else {
        return null
      }
    } catch (error) {
      await logs({ opcion: "getDocumentWithId", error })
      throw new Error(`Error Al Accesar ${collectionName}, con Documento id ${docId}`, error)
    }
  } else {
    return null
  }
}

async function getDocumentsFromCollectionWhereWhere(
  collectionName,
  fieldName1,
  condition1,
  searchValue1,
  fieldName2,
  condition2,
  searchValue2
) {
  const data = []

  const q = query(
    collection(db, `${collectionName}`),
    where(`${fieldName1}`, `${condition1}`, searchValue1),
    where(`${fieldName2}`, `${condition2}`, searchValue2)
  )

  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() })
  })

  return data
}

async function logs({ opcion, error }) {
  const fechaHora = timestamp(new Date())
  const user = store.getters["store_usuario/usuario"]
  console.log("LOGS ERROR", { user: user.email, hora: fechaHora, opcion, error })
  await addDocument("logs", { user: user.email, hora: fechaHora, opcion, error: error.message || error })
}

export {
  logs,
  timestamp,
  addDocument,
  addDocumentId,
  getAllDocsFrom,
  getDocumentWithId,
  getDocsFromCollectionWhere,
  updateDocumentId,
  deleteDocumentId,
  listeningQuery,
  getDocumentsFromCollectionWhereWhere,
  deleteDocumentsWhereWhere,
}
