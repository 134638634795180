import Vue from 'vue'
import VueRouter from 'vue-router'
import { onAuthStateChanged, auth } from '@/firebase/firebase'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '@/views/About.vue')
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import(/* webpackChunkName: "Reset" */ '@/views/Reset.vue'),
    meta: {
      NeedstoNotBeAuthenticated: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
    meta: {
      NeedstoNotBeAuthenticated: true
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/productos',
    name: 'Productos',
    component: () => import(/* webpackChunkName: "Productos" */ '@/views/Productos.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/generales',
    name: 'Generales',
    component: () => import(/* webpackChunkName: "Generales" */ '@/views/Generales.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cotizando/:renglon',
    name: 'cotizando',
    component: () => import(/* webpackChunkName: "InfoProducto" */ '@/views/ProductoCotizado.vue'),
    beforeEnter: (to, from, next) => {
      if (!from) {
        next('/')
      } else {
        next()
      }
    },
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/producto/:sku',
    name: 'producto',
    component: () => import(/* webpackChunkName: "InfoProducto" */ '@/views/Producto.vue'),
    beforeEnter: (to, from, next) => {
      const sku = to.params.sku.replace(/\//g, '~') // Reemplazar "/" con "-"
      next({ params: { sku } })
    },
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/escanqr',
    name: 'EscanQR',
    component: () => import(/* webpackChunkName: "EscanQR" */ '@/views/EscanQR.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/cliente',
    name: 'Cliente',
    component: () =>
      import(/* webpackChunkName: "Clientes" */ '@/views/Cliente.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/suspender',
    name: 'Suspender',
    component: () =>
      import(/* webpackChunkName: "Suspender" */ '@/views/Suspender.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/suspendidas',
    name: 'Suspendidas',
    component: () =>
      import(/* webpackChunkName: "Cotizaciones" */ '@/views/Suspendidas.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/entrega',
    name: 'Entrega',
    component: () =>
      import(/* webpackChunkName: "Entrega" */ '@/views/Entrega.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/preview',
    name: 'Preview',
    component: () =>
      import(/* webpackChunkName: "Preview" */ '@/views/Preview.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/config',
    name: 'Config',
    component: () =>
      import(/* webpackChunkName: "Preview" */ '@/views/Config.vue'),
    meta: {
      requiresAuth: true,
      requiresConfig: true
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresConfig = to.matched.some(x => x.meta.requiresConfig)
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const NeedstoNotBeAuthenticated = to.matched.some(x => x.meta.NeedstoNotBeAuthenticated)

  try {
    await onAuthStateChanged(auth, user => {
      if (user) {
        if (NeedstoNotBeAuthenticated) {
          next('/')
        }

        if (requiresConfig && !store.getters['store_usuario/usuario'].config) {
          next('/')
        }

        next()
      } else {
        // NO USER

        if (requiresAuth) {
          next('/login')
        } else {
          next()
        }
      }
    })
  } catch (error) {
    console.error('Error router ', error)
  }
})

export default router
