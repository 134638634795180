<template>
  <v-app>
    <v-main>
      <LoadingScreen v-if="showLoadingScreen" />
      <router-view v-if="showCotizador && !showLoadingScreen" />
    </v-main>
  </v-app>
</template>

<script>
import { auth, onAuthStateChanged } from '@/firebase/firebase'
import { getAllDocsFrom, addDocument } from '@/firebase/helpers'
import { getUserData } from '@/firebase/usuario'
import { muestraMensaje } from '@/js/utilities'
// import { actualizaTiendasMKS } from "@/firebase/para corregir"
// import { actualizaTiendasMKS } from "@/js/cargaImagenes"

export default {
  name: 'App',
  components: {
    LoadingScreen: () =>
      import(
        /* webpackChunkName: "LoadingScreen" */ '@/components/interface/LoadingScreen'
      )
  },
  data: () => ({
    showLoadingScreen: false,
    showCotizador: false
  }),

  methods: {

    async testing() {

      const data = {
        "tienda": {
          "estado": "BC",
          "colonia": "Col. 20 De Noviembre",
          "direccion": "Av. 20 De Noviembre #12177",
          "sucursal": "20 de Noviembre",
          "rfc": "KNO900118U6A",
          "ciudad": "Tijuana",
          "telefono": "(664) 681-9024",
          "razonSocial": "Kuroda Norte, S.A. de C.V.",
          "tienda": "KN00",
          "sociedad": "KNO"
        },
        "vendedor": {
          "email": "araoz@kuroda.com",
          "nombre": "Roberto Araoz Soto",
          "vendedor": "800"
        },
        "cliente": {
          "codigo": "",
          "nombre": "Chabela",
          "calle": "",
          "numero": "",
          "colonia": "",
          "ciudad": "Culiacán",
          "estado": "SIN",
          "pais": "México",
          "codigoPostal": "",
          "rfc": "",
          "email": "roberto.araoz@gmail.com",
          "telefono": ""
        },
        "canal": "Menudeo",
        "ordenCompra": "",
        "tipoEntrega": "Entrega inmediata",
        "tipoVenta": "Contado",
        "cotizacionSap": "0414142625",
        "cotizacionId": "IKBNJGbhFH4A8dMvlS57",
        "fechaElaboracion": "25 octubre 2022 / 05:27 PM",
        "productos": [
          {
            "tipo": "producto",
            "unidadMedida": "CJ4",
            "marca": "TENDENZZA",
            "sku": "TPPP02260S",
            "categoria": "PISOS",
            "descripcion": "PISO TRENTO 60X60 PULIDO MICR 1.44M SLIM",
            "precioUnitarioSinIva": "$614.48",
            "precioUnitarioConIva": "$663.64",
            "importeAcumuladoSinIva": "$30,724.00",
            "importeAcumuladoConIva": "$33,181.92",
            "cantidad": "50",
            "renglon": 0
          },
          {
            "tipo": "producto",
            "unidadMedida": "CAJ",
            "marca": "PORCELANITE",
            "sku": "PPADW185554",
            "categoria": "PISOS",
            "descripcion": "PISO ADWOOD FD 18X55 NATURAL 1.69M2 1A",
            "precioUnitarioSinIva": "$313.30",
            "precioUnitarioConIva": "$338.36",
            "importeAcumuladoSinIva": "$313.30",
            "importeAcumuladoConIva": "$338.36",
            "cantidad": 1,
            "renglon": 1
          }
        ],
        "totales": {
          "subTotal": "$31,037.30",
          "tasaIva": "8%",
          "importeIva": "$2,482.98",
          "importeTotalPesos": "$33,520.28",
          "importeTotalDolares": "$1,718.99",
          "tipoCambio": "$19.50"
        }
      }

      try {
        await addDocument("email", data)
      } catch (error) {
        console.error(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", error)
      }

    }

  },

  mounted() { },
  async created() {
    // console.log("Dispositivo en Modo", this.$vuetify.breakpoint.name)

    try {
      onAuthStateChanged(auth, async (user) => {

        if (!user) {
          this.showLoadingScreen = false
          this.showCotizador = true
          return
        }

        this.showLoadingScreen = true
        const aplicacion = await getAllDocsFrom('aplicacion')

        // await actualizaTiendasMKS();

        if (aplicacion[0].mantenimiento) {
          muestraMensaje(
            'info',
            'Aplicación en mantenimiento, intente mas tarde.'
          )
          
          await auth.signOut()

          this.showLoadingScreen = false
          this.showCotizador = true
          return
        }

        const email = user.email.toLowerCase()
        console.log("email", email)

        // const email = "francisco.sarabia@kuroda.com"
        const userInfo = await getUserData(email)

        if (userInfo) {
          this.$store.dispatch('store_cotizando/limpia')
          this.$store.dispatch(
            'store_cotizando/recalcula_totales'
          )
        } else {
          muestraMensaje(
            'error',
            'Usuario Sin Configuración, reportelo al administrador'
          )
          auth.signOut()
        }

        this.showLoadingScreen = false
        this.showCotizador = true
      })
    } catch (error) {
      console.log('Error APP', error)
    }
  }
}
</script>

