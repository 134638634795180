import { initializeApp, getApp } from 'firebase/app'
import { deleteDoc, deleteField, updateDoc, collection, query, where, getDocs, doc, getDoc, getDocFromServer, getDocsFromCache, addDoc, onSnapshot, setDoc, Timestamp, enableIndexedDbPersistence, initializeFirestore, CACHE_SIZE_UNLIMITED } from 'firebase/firestore'

import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
// import { getRemoteConfig, fetchAndActivate, getBoolean } from "firebase/remote-config"

// import { getStorage } from 'firebase/storage'
// // import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
// // import { getMessaging, getToken } from 'firebase/messaging';
import { getPerformance, trace } from 'firebase/performance';
import { getAnalytics, logEvent } from 'firebase/analytics'


const firebaseConfig = {
  apiKey: 'AIzaSyAoJ0_5Zs3anUkgnwtnzwG5zjz9WMyxae8',
  authDomain: 'cotizador-326117.firebaseapp.com',
  projectId: 'cotizador-326117',
  storageBucket: 'cotizador-326117.appspot.com',
  messagingSenderId: '919183136616',
  appId: '1:919183136616:web:9b9bffe8c94429f80f28dd',
  measurementId: 'G-65TB122W58'
}

let firebaseApp, auth, db, functions, analytics, performance

try {
  firebaseApp = initializeApp(firebaseConfig)
  auth = getAuth(firebaseApp)
  // functions = getFunctions(firebaseApp)
  functions = getFunctions(getApp())

  db = initializeFirestore(firebaseApp, {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    ignoreUndefinedProperties: true
  })
  analytics = getAnalytics(firebaseApp)
  performance = getPerformance(firebaseApp)
  // remoteConfig = getRemoteConfig()
  // remoteConfig.defaultConfig = {
  //   "app_en_mantenimiento": false
  // }

  // if (process.env.NODE_ENV == "development") {
  //   remoteConfig.settings.minimumFetchIntervalMillis = 1000
  // } else {
  //   remoteConfig.settings.minimumFetchIntervalMillis = 3600000
  // }

  // fetchAndActivate(remoteConfig)
  //   .then(() => {
  //     app_en_mantenimiento = getBoolean(remoteConfig, "app_en_mantenimiento")
  //     console.log("remoteConfig dice en manto", app_en_mantenimiento)
  //   })
  //   .catch((err) => {
  //     console.log('error de remoteconfig', err)
  //   })

} catch (error) {
  console.error('initializeApp Error', error)
}

// auth.signOut()
// const firestore = getFirestore(firebaseApp)
// const storage = getStorage(firebaseApp)

// *** HABILITAR EMULADOR DE LAS GCF
// import { connectFunctionsEmulator } from 'firebase/functions'

// connectFunctionsEmulator(functions, "localhost", 5001)
// firebase emulators:start

enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
      // console.log("Error: Multiple tabs open, persistence can only be enabled in one tab at a time")
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
      // console.log("Error: The current browser does not support all of the features required to enable persistence")
    }
  })

export {
  analytics, logEvent, performance, trace,
  firebaseApp, auth, onAuthStateChanged, signInWithEmailAndPassword,
  collection, where, getDoc, getDocFromServer, getDocsFromCache,
  doc, addDoc, onSnapshot, db, getFunctions, getDocs, query,
  httpsCallable, setDoc, Timestamp, functions, deleteField, updateDoc, deleteDoc
}
