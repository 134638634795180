
const tiendas = {
  namespaced: true,

  state: () => ({
    tiendas: [],
    tiendasExistencia: []
  }),

  getters: {
    tiendas: (state) => {
      return state.tiendas
    },
    tiendasExistencia: (state) => {
      return state.tiendasExistencia
    },
    arrayTiendasExistencia: (state) => {
      return state.tiendasExistencia.map(item => { return item.tienda })
    }
  },
  mutations: {
    CAMBIA_TIENDAS (state, tiendas) {
      state.tiendas = tiendas
    },
    CAMBIA_TIENDAS_EXISTENCIA (state, tiendasExistencia) {
      state.tiendasExistencia = tiendasExistencia
    },
    CAMBIA_TIPO_CAMBIO_TIENDA (state, { tienda, nuevoTipoCambio }) {
      const pos = state.tiendas.map(function (e) {
        return e.tienda
      }).indexOf(tienda.tienda)

      state.tiendas[pos].tipoCambio = nuevoTipoCambio
      state.tiendas[pos].ultimaActualizacion = new Date()

    }
  },
  actions: {
    cambia_tiendas ({ commit }, tiendas) {
      commit('CAMBIA_TIENDAS', tiendas)
    },
    cambia_tiendasExistencia ({ commit }, tiendasExistencia) {
      commit('CAMBIA_TIENDAS_EXISTENCIA', tiendasExistencia)
    },
    cambia_tipoCambioTienda ({ commit }, { tienda, nuevoTipoCambio }) {
      commit('CAMBIA_TIPO_CAMBIO_TIENDA', { tienda, nuevoTipoCambio })
    }
  }
}

export default tiendas
