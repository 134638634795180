import store from '@/store'

import dayjs from 'dayjs'
import 'dayjs/locale/es-mx'
dayjs.locale('es-mx')

function fechaConFormatoString(fecha, incluirHora) {
  let formato = 'DD MMMM YYYY'
  if (incluirHora) {
    formato = 'DD MMMM YYYY / hh:mm A'
  }
  return dayjs(fecha).format(formato)
}

function convertirFechaStringToDate(fechaString) {
  // debe llegar "2011-02-15T20:39:33.000+0000"
  return dayjs(fechaString).toDate()
}

function esfechaMenorA(fecha, cantidad, unidades) {
  // Tienda ya pasaron 4 hrs?
  return dayjs(fecha) < dayjs().subtract(cantidad, unidades)
}

function mayusculasNombresPropios(string = '') {
  return string.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
}

function mayusculaLaPrimerLetra(str = '') {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
/**
 * @param  {string} texto
 */
function mayusculas(texto) {
  return ('' + texto).toUpperCase()
}

function isObjectEmpty(object) {
  return (Object.entries(object).length === 0)
}

function areObjectsEqual(object1, object2) {
  if (!object1) { object1 = { empty: true } }
  if (!object2) { object2 = { empty: true } }

  const jsonKeys = Object.keys(object1)
  let areEqual = true
  for (const key of jsonKeys) {
    if (object1[key] !== object2[key]) {
      areEqual = false
    }
  }

  return areEqual
}

function muestraMensaje(color, mensaje) {
  console.info(color, mensaje)
  store.dispatch('store_controles/cambia_mensaje', {
    color: color,
    mensaje: mensaje,
    visible: true
  })
}

export { mayusculasNombresPropios, mayusculaLaPrimerLetra, mayusculas, isObjectEmpty, muestraMensaje, areObjectsEqual, esfechaMenorA, convertirFechaStringToDate, fechaConFormatoString }
