'use strict'

import Vue from 'vue'
import Vuex from 'vuex'

import controles from './modules/controles'
import usuario from './modules/usuario'
import cotizando from './modules/cotizando'
import tiendas from './modules/tiendas'
// import nested from './modules/nested-store'

Vue.use(Vuex)
export default new Vuex.Store({
  strict: true,
  modules: {
    store_controles: controles,
    store_usuario: usuario,
    store_cotizando: cotizando,
    store_tiendas: tiendas
    // nested: nested
  }
})
