import { logs } from '@/firebase/helpers'
import store from '@/store'
import { muestraMensaje } from '@/js/utilities'
import { analytics, logEvent } from "@/firebase/firebase"


const baseURL = 'https://cotizadorb.kuroda.com/apiCotizador'
const axios = require("axios").default

const apiConfig = {
  auth: {
    username: "cotizadorApi",
    password: "CoT1zAd0r4p1",
  },
  timeout: 5000, // 5 segundos psara timeout (ha tardado hasta 4000)
}

export async function apiSap(url, data) {

  try {
    const response = await axios.post(`${baseURL}/${url}`, data, apiConfig)

    console.log("apiSap", response.data)

    store.dispatch('store_controles/cambia_conexionSAP', true)
    if (response.data.code === 200 || response.data.code === 201 /*  TODO OK */) {
      return { datos: response.data.datos, error: null }
    }
    else {
      logEvent(analytics, 'api_Error', { url, error: response.data.message})
      return { datos: null, error: response.data.message }
    }

  } catch (error) {
    store.dispatch('store_controles/cambia_conexionSAP', false)
    logEvent(analytics, 'api_Error', { url, error: error.message })
    return { datos: null, error: error?.message }
  }
}

export async function apiSapOLD(url, data) {
  console.log('Consumiendo API', url, data)

  // if (sapDesarrollo) {
  //     // POSTMAN http://201.165.118.230:8084/WsCotizador/apiCotizador/cliente
  //     baseURL = "https://cotizadorbdes.kuroda.com/apiCotizador" //Desarrollo
  // } else {
  // POSTMAN http://201.165.118.230:8080/WsCotizador/apiCotizador/cliente
  const baseURL = 'https://cotizadorb.kuroda.com/apiCotizador' // Productivo
  // }

  const config = {
    url: url,
    method: 'post',
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
      // "Access-Control-Allow-Origin": "*"
    },
    data: data,
    withCredentials: true,
    auth: {
      username: 'cotizadorApi',
      password: 'CoT1zAd0r4p1'
    },
    responseType: 'json',
    timeout: 1 // SIMULAR TIMEOUT
    // timeout: 5000 // 5 segundos psara timeout (ha tardado hasta 4000)
  }

  store.dispatch('store_controles/cambia_conexionSAP', true)

  try {
    const apiResponse = await axios(config)

    if (apiResponse.data.code === 400 /* No se pudo Grabar */) {
      muestraMensaje('warning', `No ha sido posible grabar cotización en Sap ${apiResponse.data.message}`)
      return null
    }

    if (apiResponse.data.code === 403 /* Sin Derechos */) { return null }
    if (apiResponse.data.code === 404 /* Producto No Encontrado */) { return null }

    // console.log("API RESPONSE:", apiResponse.data.datos)
    return apiResponse.data.datos
  } catch (error) {
    store.dispatch('store_controles/cambia_conexionSAP', false)
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log('Error response1', error.response.data)
      // console.log(`Error api ${url}`, error.response.status)
      await logs({ opcion: `Api ${url}`, error: { url: baseURL + '/' + url, response: error.response.status } })
      // console.log('Error response3', error.response.headers)
    } else if (error.request) {
      // The request was made but no response was received
      // cuando hace timeout
      // console.log(error.request)
      // console.log("ERROR: Sin respuesta en API", url)
      await logs({ opcion: `Sin Respuesta en Api ${url}`, error })
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log(`Error creando Request api ${url}`, error.message)
      await logs({ opcion: `Creando Api Request ${url}`, error: { data, response: error.message } })
    }

    return null
  }
}
