import { getAllDocsFrom, getDocsFromCollectionWhere, getDocumentWithId } from '@/firebase/helpers'
import { auth, analytics, logEvent } from '@/firebase/firebase'
import { muestraMensaje } from '@/js/utilities'
import store from '@/store'

async function getUserData(email) {
  /** @type {String} */

  const userData = await getDocumentWithId('usuarios', email)

  if (userData) {
    if (userData.vendedor == null) {
      userData.vendedor = 'Z02'
    }

    store.dispatch('store_usuario/cambia_usuario', userData)
    store.dispatch('store_cotizando/cambia_canal', userData.canalSeleccionado) // MENUDEO MAYOREO
    store.dispatch('store_cotizando/cambia_tipoVenta', userData.tipoVentaSeleccionado) // CREDITO CONTADO
    store.dispatch('store_cotizando/cambia_tipoEntrega', userData.tipoEntregaSeleccionado)// ENTREGA INMEDIATA

    // DERECHOS DEL VENDEDOR
    const promises = []
    promises[0] = tiendasUsuario(userData.tiendas)
    promises[1] = tiendasExistencia(userData.sociedad)
    logEvent(analytics, 'user_login', { email })

    await Promise.all(promises)

    return userData
  } else {
    store.dispatch('store_usuario/cambia_usuario', {})
    return null
  }
}

async function tiendasUsuario(tiendas /* ['KN00','KN01'] */) {
  const tiendaDefault = store.getters['store_usuario/usuario'].tiendaDefault

  const tiendasData = []
  let tiendaSeleccionada = null

  const tdas = await getDocsFromCollectionWhere('tiendas', 'tienda', 'in', tiendas)

  for (const tda of tdas) {
    tda.ultimaActualizacion = tda.ultimaActualizacion.toDate() // Covertir Fecha FB to JS
    if (tda.tienda == tiendaDefault) {
      tiendaSeleccionada = tda
    }
    tiendasData.push(tda)
  }

  if (!tiendaSeleccionada) {
    tiendaSeleccionada = tiendasData[0].tienda
    muestraMensaje('error', 'Configuración Incorrecta, reportelo al administrador: tiendaDefault ')
    auth.signOut()
  }

  store.dispatch('store_tiendas/cambia_tiendas', tiendasData)
  store.dispatch('store_cotizando/cambia_tienda', tiendaSeleccionada)

  return tiendasData
}

async function tiendasExistencia(sociedad /* 'KNO' */) {
  // Tiendas de todo KNO para mostrar al solicitar Existencias

  let tiendas = []
  // const existenciasOtrasRazonesSociales = store.getters["store_usuario/usuario"].existenciasOtrasRazonesSociales
  const existenciasOtrasRazonesSociales = false // Por mientras
  if (existenciasOtrasRazonesSociales) {
    tiendas = await getAllDocsFrom('tiendas')
  } else {
    tiendas = await getDocsFromCollectionWhere('tiendas', 'sociedad', '==', sociedad)
  }

  const tiendasExistencia = []

  for (const tienda of tiendas) {
    tiendasExistencia.push({
      sociedad: tienda.sociedad,
      ciudad: tienda.ciudad,
      sucursal: tienda.sucursal,
      tienda: tienda.tienda
    })
  }

  store.dispatch('store_tiendas/cambia_tiendasExistencia', tiendasExistencia)
}

export { getUserData }
