
const usuario = {
  namespaced: true,

  state: () => ({
    usuario: {}
  }),

  getters: {
    usuario: (state) => {
      return state.usuario
    },
    canales: (state) => {
      return state.usuario.canales
    },
    tiposVenta: (state) => {
      return state.usuario.tiposVenta
    },
    tiposEntrega: (state) => {
      return state.usuario.tiposEntrega
    },
    tasasIva: (state) => {
      return state.usuario.tasasIva
    },
    imagenCotizando: (state) => {
      return state.usuario.imagenCotizando
    },
    preciosConIva: (state) => {
      return state.usuario.preciosConIva
    },
    infoProductoQR: (state) => {
      return state.usuario.infoProductoQR
    },
    existenciasOtrasRazonesSociales: (state) => {
      return state.usuario.existenciasOtrasRazonesSociales
    }
  },
  mutations: {
    CAMBIA_USUARIO (state, usuario) {
      state.usuario = usuario
    },
    CAMBIA_IMAGEN_COTIZANDO (state, imagenCotizando) {
      state.usuario.imagenCotizando = imagenCotizando
    },
    CAMBIA_PRECIOS_CON_IVA (state, preciosConIva) {
      state.usuario.preciosConIva = preciosConIva
    },
    CAMBIA_INFO_PRODUCTO_QR (state, infoProductoQR) {
      state.usuario.infoProductoQR = infoProductoQR
    },
    CAMBIA_EXISTENCIAS_OTRAS_RAZONES_SOCIALES (state, existenciasOtrasRazonesSociales) {
      state.usuario.existenciasOtrasRazonesSociales = existenciasOtrasRazonesSociales
    }
  },
  actions: {
    cambia_usuario ({ commit }, usuario) {
      commit('CAMBIA_USUARIO', usuario)
    },
    cambia_imagenCotizando ({ commit }, imagenCotizando) {
      commit('CAMBIA_IMAGEN_COTIZANDO', imagenCotizando)
    },
    cambia_preciosConIva ({ commit }, preciosConIva) {
      commit('CAMBIA_PRECIOS_CON_IVA', preciosConIva)
    },
    cambia_infoProductoQR ({ commit }, infoProductoQR) {
      commit('CAMBIA_INFO_PRODUCTO_QR', infoProductoQR)
    },
    cambia_existenciasOtrasRazonesSociales ({ commit }, existenciasOtrasRazonesSociales) {
      commit('CAMBIA_EXISTENCIAS_OTRAS_RAZONES_SOCIALES', existenciasOtrasRazonesSociales)
    }
  }
}

export default usuario
