'use strict'

import { Timestamp } from '@/firebase/firebase'
import { actualizaTipoCambioTiendaSeleccionada } from '@/firebase/tienda'
import { Cliente, Entrega } from '@/js/declarations'

const cotizando = {
  namespaced: true,

  state: () => ({
    tienda: {},
    cliente: new Cliente(),
    direccionEntrega: new Entrega(),
    productos: [],
    totales: {},
    tipoVenta: '',
    canal: '',
    tipoEntrega: '',
    ordenCompra: '',
    cotizacionSap: '',
    tasaIva: 0
  }),

  getters: {
    tienda: (state) => {
      return state.tienda
    },
    cliente: (state) => {
      return state.cliente
    },
    direccionEntrega: (state) => {
      return state.direccionEntrega
    },
    productos: (state) => {
      return state.productos
    },
    totales: (state) => {
      return state.totales
    },
    tipoVenta: (state) => {
      return state.tipoVenta
    },
    canal: (state) => {
      return state.canal
    },
    tipoEntrega: (state) => {
      return state.tipoEntrega
    },
    tasaIva: (state) => {
      return state.tasaIva
    },
    ordenCompra: (state) => {
      return state.ordenCompra
    },
    ultimaActualizacionTipoCambio: (state) => {
      return state.tienda.ultimaActualizacion
    },
    cotizando: (state, getters, rootState, rootGetters) => {
      // const nullMe = (value) => {
      // 	return (value == undefined || value == null) ? null : value
      // }

      const usuario = rootGetters['store_usuario/usuario']

      const vendedor = {
        email: usuario.email,
        nombre: usuario.nombre,
        vendedor: usuario.vendedor
      }

      const cliente = rootGetters['store_cotizando/cliente']
      const clienteData = { ...cliente }

      const cotizacion = {
        vendedor: vendedor,
        cliente: clienteData,
        canal: state.canal,
        ordenCompra: state.ordenCompra,
        productos: state.productos,
        tienda: state.tienda,
        tipoEntrega: state.tipoEntrega,
        tipoVenta: state.tipoVenta,
        totales: state.totales,
        cotizacionSap: state.cotizacionSap,
        fechaElaboracion: Timestamp.fromDate(new Date())
      }

      if (state.tipoEntrega == 'Envío a domicilio') {
        const direccionEntrega = rootGetters['store_cotizando/direccionEntrega']
        const direccionEntregaData = { ...direccionEntrega }
        cotizacion.direccionEntrega = direccionEntregaData
      }

      return cotizacion
    },

    cotizacionSap: (state) => {
      return state.cotizacionSap
    }

  },
  mutations: {
    CAMBIA_TIENDA (state, tienda) {
      state.tienda = tienda
    },
    CAMBIA_TIPO_VENTA (state, tipoVenta) {
      state.tipoVenta = tipoVenta
    },
    CAMBIA_CANAL (state, canal) {
      state.canal = canal
    },
    CAMBIA_TIPO_ENTREGA (state, tipoEntrega) {
      state.tipoEntrega = tipoEntrega
    },
    CAMBIA_TASA_IVA (state, tasaIva) {
      state.tasaIva = tasaIva
    },
    CAMBIA_ORDEN_COMPRA (state, ordenCompra) {
      state.ordenCompra = ordenCompra
    },
    CAMBIA_CLIENTE (state, cliente) {
      state.cliente = cliente
    },
    CAMBIA_DIRECCION_ENTREGA (state, direccionEntrega) {
      state.direccionEntrega = direccionEntrega
    },
    AGREGA_PRODUCTO (state, producto) {
      state.productos.push(producto)
    },
    CAMBIA_PRODUCTO (state, producto) {
      state.productos[producto.renglon] = producto
    },
    ESTABLECE_PRECIO_CANAL (state, { producto, precio }) {
      state.productos[producto.renglon].precio = precio
    },
    ELIMINA_PRODUCTO (state, index) {
      state.productos.splice(index, 1)
    },
    ELIMINA_TODOS_PRODUCTOS (state) {
      state.productos = []
    },
    CAMBIA_TIPO_CAMBIO (state, tipoCambio) {
      state.tienda.tipoCambio = tipoCambio
      state.tienda.ultimaActualizacion = new Date()
    },
    RECALCULA_TOTALES (state) {
      if (state.productos.length == 0) {
        state.totales = {}
      }

      const tienda = this.getters['store_cotizando/tienda']
      const canal = this.getters['store_cotizando/canal']

      let subTotal = 0

      state.productos.map(item => {
        if (item.tipo == 'producto') {
          const precio = canal == 'Menudeo' ? item.precioMenudeo : item.precioMayoreo
          const importeAcumulado = (precio - item.descuentoPesos) * item.cantidad
          subTotal = subTotal + importeAcumulado
          state.productos[item.renglon].importeAcumulado = importeAcumulado
        }
      })

      const tasaIva = this.getters['store_cotizando/tasaIva']
      const tasaIVAPorcentaje = tasaIva / 100

      const tipoCambio = tienda.tipoCambio

      const importeIva = tasaIVAPorcentaje * subTotal
      const importeTotalPesos = subTotal + importeIva
      const importeTotalDolares = importeTotalPesos / tipoCambio

      state.totales = { subTotal, tasaIva, tasaIVAPorcentaje, importeIva, importeTotalPesos, importeTotalDolares, tipoCambio }
    },

    CAMBIA_COTIZACION_SAP (state, cotizacionSap) {
      state.cotizacionSap = cotizacionSap
    },
    CORREGIR_RENGLON_PRODUCTOS (state) {
      for (let index = 0; index < state.productos.length; index++) {
        state.productos[index].renglon = index
      }
    }

  },
  actions: {
    cambia_tienda ({ commit }, tienda) {
      commit('CAMBIA_TIENDA', tienda)
      commit('CAMBIA_TASA_IVA', tienda.tasaIva)
    },
    cambia_tipoVenta ({ commit }, tipoVenta) {
      commit('CAMBIA_TIPO_VENTA', tipoVenta)
      commit('RECALCULA_TOTALES')
    },
    cambia_canal ({ commit }, canal) {
      commit('CAMBIA_CANAL', canal)
      commit('RECALCULA_TOTALES')
    },
    cambia_tipoEntrega ({ commit }, tipoEntrega) {
      commit('CAMBIA_TIPO_ENTREGA', tipoEntrega)
      commit('RECALCULA_TOTALES')
    },
    cambia_tasaIva ({ commit }, tasaIva) {
      commit('CAMBIA_TASA_IVA', tasaIva)
      commit('RECALCULA_TOTALES')
    },
    cambia_ordenCompra ({ commit }, ordenCompra) {
      commit('CAMBIA_ORDEN_COMPRA', ordenCompra)
    },
    cambia_cliente ({ commit }, cliente) {
      commit('CAMBIA_CLIENTE', cliente)
    },
    cambia_direccionEntrega ({ commit }, direccionEntrega) {
      commit('CAMBIA_DIRECCION_ENTREGA', direccionEntrega)
    },
    agrega_producto ({ commit }, producto) {
      commit('AGREGA_PRODUCTO', producto)
      commit('RECALCULA_TOTALES')
    },
    cambia_producto ({ commit }, producto) {
      commit('CAMBIA_PRODUCTO', producto)
      commit('RECALCULA_TOTALES')
    },
    elimina_producto ({ commit }, index) {
      commit('ELIMINA_PRODUCTO', index)
      commit('CORREGIR_RENGLON_PRODUCTOS')
      commit('RECALCULA_TOTALES')
    },
    recalcula_totales ({ commit }) {
      commit('RECALCULA_TOTALES')
    },
    cambia_cotizacionSap ({ commit }, cotizacionSap) {
      commit('CAMBIA_COTIZACION_SAP', cotizacionSap)
    },
    cambia_tipoCambio ({ commit }, tipoCambio) {
      commit('CAMBIA_TIPO_CAMBIO', tipoCambio)
      commit('RECALCULA_TOTALES')
    },
    async limpia ({ commit, getters }) {
      commit('ELIMINA_TODOS_PRODUCTOS')
      commit('CAMBIA_CLIENTE', new Cliente())
      commit('CAMBIA_DIRECCION_ENTREGA', new Entrega())
      commit('CAMBIA_COTIZACION_SAP', '')
      commit('CAMBIA_ORDEN_COMPRA', '')

      await actualizaTipoCambioTiendaSeleccionada()

      commit('CAMBIA_TASA_IVA', getters.tienda.tasaIva) // Devolver la tasa de iva a la de la tienda
      commit('RECALCULA_TOTALES')
    }
  }
}

export default cotizando
